body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #351905;
  background-image: url(../../assets/images/black-linen.png);
  color:antiquewhite;
}

.appTitle {
  font-family: 'Great Vibes', cursive;
  font-size: 2rem;
  color: #DEB887;
  font-weight: 550;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  width:100%;
}