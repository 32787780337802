.carbiRow {
  color:bisque;
}

.carbiRow td {
  cursor: pointer;
  padding: .3rem .4rem;
}

.caret {
  font-size: 1rem;
  padding: .2rem;
}

.favorable {
  color: seagreen;
}

.unfavorable {
  color: darkred;
}

.delete-button {
  color: bisque;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 1.2rem;
}

.expanded {
  color: goldenrod;
  font-size: .9rem;
}
