.btn {
  display: inline-block;
  background: #351905;
  color: burlywood;
  border: none;
  padding: 6px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

input {
  width: 80%;
  height: 22px;
  margin: 5px;
  padding: 2px 2px;
  font-size: 16px;
}